import React, { useState, useEffect } from 'react';
import CustomNavbar from './Navbar';
import Footer from './footer';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faCoins, faPhone } from '@fortawesome/free-solid-svg-icons';
import backgroundImage1 from './images/1.png'
import backgroundImage2 from './images/2.png'
import backgroundImage3 from './images/3.png'
import backgroundImage4 from './images/4.png'
import backgroundImage5 from './images/5.png'
import backgroundImage6 from './images/6.png'
import backgroundImage1Mobile from './images/1_mob.png'
import backgroundImage2Mobile from './images/2_mob.png'
import backgroundImage3Mobile from './images/3_mob.png'
import backgroundImage4Mobile from './images/4_mob.png'
import backgroundImage5Mobile from './images/5_mob.png'
import backgroundImage6Mobile from './images/6_mob.png'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { faClipboardList, faMapMarkedAlt, faComments, faLuggageCart, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import GroupProvide from './images/group-provide.svg'
import WomenProvide from './images/woman-provide.svg'
import CorporateProvide from './images/corporate-provide.svg'
import CustomiseProvide from './images/customise-provide.svg'
import BackpackProvide from './images/backpack-provide.svg'
import LuxuryProvide from './images/luxury-provide.svg'
import FamilyProvide from './images/family-provide.svg'
import CoupleProvide from './images/couple-provide.svg'
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-bootstrap';
import { faMapLocationDot, faPassport, faPlaneCircleCheck, faHotel, faIdCard, faCar } from '@fortawesome/free-solid-svg-icons';
import NewYearPoster from './images/Black and Gold New Year Party Flyer.png'
import { Modal, Button } from "react-bootstrap";

const images = [
  backgroundImage1,
  backgroundImage2,
  backgroundImage3,
  backgroundImage4,
  backgroundImage5,
  backgroundImage6
];

const imagesMobile = [
  backgroundImage1Mobile,
  backgroundImage2Mobile,
  backgroundImage3Mobile,
  backgroundImage4Mobile,
  backgroundImage5Mobile,
  backgroundImage6Mobile
];

function Home() {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageIndexMobile, setCurrentImageIndexMobile] = useState(0);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.defer = true;
    script.setAttribute('data-use-service-core', '');
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndexMobile((prevIndex) => (prevIndex + 1) % imagesMobile.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup when the component mounts
    setShowPopup(true);
  }, []);

  const handleClose = () => setShowPopup(false);
  
  return (
    <div style={{width: '100%'}}>
      <CustomNavbar />  
      <Modal show={showPopup} onHide={handleClose} centered>
        <Modal.Body>
          <img src={NewYearPoster} style={{width: '100%'}} alt="" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{width: '100%'}}>
        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
        <div class="elfsight-app-42fab5be-803c-4c04-b868-5c650f0333d0" data-elfsight-app-lazy></div>
      </div>
      <div>
        <Carousel interval={3000} pause="false" fade={true}>
          <Carousel.Item>
            <img
              src={!isMobile ? backgroundImage1 : backgroundImage1Mobile}
              className="background-image-cover"
              alt="First slide"
            />
            <div className="center-text-home">
              <h1 className='catchPhrase'>Embark on a Journey of a Lifetime</h1>
              <p className='subCatch'>Discover unforgettable travel experiences that will leave you breathless with Flip To Trip</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={!isMobile ? backgroundImage2 : backgroundImage2Mobile}
              className="background-image-cover"
              alt="First slide"
            />
            <div className="center-text-home">
              <h1 className='catchPhrase'>Embark on a Journey of a Lifetime</h1>
              <p className='subCatch'>Discover unforgettable travel experiences that will leave you breathless with Flip To Trip</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={!isMobile ? backgroundImage3 : backgroundImage3Mobile}
              className="background-image-cover"
              alt="First slide"
            />
            <div className="center-text-home">
              <h1 className='catchPhrase'>Embark on a Journey of a Lifetime</h1>
              <p className='subCatch'>Discover unforgettable travel experiences that will leave you breathless with Flip To Trip</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={!isMobile ? backgroundImage4 : backgroundImage4Mobile}
              className="background-image-cover"
              alt="First slide"
            />
            <div className="center-text-home">
              <h1 className='catchPhrase'>Embark on a Journey of a Lifetime</h1>
              <p className='subCatch'>Discover unforgettable travel experiences that will leave you breathless with Flip To Trip</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={!isMobile ? backgroundImage5 : backgroundImage5Mobile}
              className="background-image-cover"
              alt="First slide"
            />
            <div className="center-text-home">
              <h1 className='catchPhrase'>Embark on a Journey of a Lifetime</h1>
              <p className='subCatch'>Discover unforgettable travel experiences that will leave you breathless with Flip To Trip</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={!isMobile ? backgroundImage6 : backgroundImage6Mobile}
              className="background-image-cover"
              alt="First slide"
            />
            <div className="center-text-home">
              <h1 className='catchPhrase'>Embark on a Journey of a Lifetime</h1>
              <p className='subCatch'>Discover unforgettable travel experiences that will leave you breathless with Flip To Trip</p>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="travel-info-container">
        <div className="travel-info-item">
          <FontAwesomeIcon icon={faPlane} size="2x" color="white" />
          <h2>Travel Across the Globe</h2>
          <p>
            Explore the world with our personalized and budget-friendly travel packages to over 500 destinations worldwide.
          </p>
        </div>
        <div className="travel-info-item">
          <FontAwesomeIcon icon={faCoins} size="2x" color="white"/>
          <h2>Best Price Guarantee</h2>
          <p>
            Travel with peace of mind and book with us, knowing you'll always get the best deal possible.
          </p>
        </div>
        <div className="travel-info-item">
          <FontAwesomeIcon icon={faPhone} size="2x" color="white"/>
          <h2>Top Notch Support</h2>
          <p>
            Experience unparalleled customer service with our team of travel experts, always here to support you on your journey.
          </p>
        </div>
      </div>
      <div className="about-us">
      <section className="our-story"> 
        <h2 className='home-page-title1'>Discover Your Perfect Travel Experience</h2>
        <p>
          What sets us apart from other travel booking platforms is our commitment to 
          personalized service and user-friendly technology. Our platform is designed to make 
          it easy for you to search and compare travel options, so you can find the best deals and book 
          your trip with confidence. We also offer customized travel planning services to help you create 
          a unique itinerary that meets your individual needs and preferences.
        </p>
      </section>  
      <Container>
        <Row>
          <Col md={4}>
            <Card className="mt-3">
              <div className="text-center">
                <FontAwesomeIcon icon={faClipboardList} size="3x" className="iconCard" />
              </div>
              <Card.Body className='cardCustom'>
                <Card.Title style={{textAlign: 'center'}}>Share Your Travel Preferences</Card.Title>
                <Card.Text>
                  Unlock your dream vacation by sharing your interests, budget, and requirements through our website form. Let's create a tailor-made trip crafted just for you!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mt-3">
              <div className="text-center">
                <FontAwesomeIcon icon={faMapMarkedAlt} size="3x" className="iconCard" />
              </div>
              <Card.Body className='cardCustom'>
                <Card.Title style={{textAlign: 'center'}}>Receive Personalized Itinerary</Card.Title>
                <Card.Text>
                  Our expert team curates a personalized itinerary, including accommodations, transportation, and activities, to match your travel preferences and desires.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mt-3">
              <div className="text-center">
                <FontAwesomeIcon icon={faComments} size="3x" className="iconCard" />
              </div>
              <Card.Body className='cardCustom'>
                <Card.Title style={{textAlign: 'center'}}>Refine Your Itinerary</Card.Title>
                <Card.Text>
                Your input truly matters! Provide feedback on the itinerary, and we'll fine-tune every detail, ensuring it's a seamless reflection of your dream getaway. Your satisfaction is our top priority.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mt-3">
            <Card>
              <div className="text-center">
                <FontAwesomeIcon icon={faLuggageCart} size="3x" className="iconCard" />
              </div>
              <Card.Body className='cardCustom'>
                <Card.Title style={{textAlign: 'center'}}>Book Your Trip</Card.Title>
                <Card.Text>
                  Relax as we handle all bookings - flights, hotels, visa assistance, insurance, transport, and tours. Experience a stress-free booking process and focus on the excitement of your upcoming adventure.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mt-3 d-flex align-items-center">
            <Card>
              <div className="text-center">
                <FontAwesomeIcon icon={faHandsHelping} size="3x" className="iconCard"/>
              </div>
              <Card.Body className='cardCustom'>
                <Card.Title style={{textAlign: 'center'}}>Get Support Throughout Your Trip</Card.Title>
                <Card.Text>
                  Throughout your journey, we've got your back. Our dedicated support team is available to assist with any needs or changes, ensuring you have a blissful and unforgettable vacation!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>    
    </div>
    <div>
            <div style={{width: '81%', margin: 'auto'}}>
                <h2 className='home-page-title1'>What We Provide</h2>
            </div>
            <Container className="d-flex" style={{ padding: '1em 1em 0' }}>
                <Row>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faMapLocationDot} />
                        <p className='what-provide-text'>Itinerary (Day-Wise)</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faPassport} />
                        <p className='what-provide-text'>Visa Assistance</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faPlaneCircleCheck} />
                        <p className='what-provide-text'>Flight Bookings</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faHotel} />
                        <p className='what-provide-text'>Hotel Bookings</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faIdCard} />
                        <p className='what-provide-text'>Insurance (Travel & Medical)</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faCar} />
                        <p className='what-provide-text'>Transportation (Car, Bus, Train, Cruise)</p>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div style={{margin: '1em'}}>
            <div style={{width: '81%', margin: 'auto'}}>
                <h2 className='home-page-title1'>Our Services</h2>
            </div>
            <Container className="d-flex" style={{ padding: '2em 0' }}>
                <Row>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={GroupProvide} className='what-provide-svg' alt="Group Tours" />
                        <p className='what-provide-svg-text'>Group Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={WomenProvide} className='what-provide-svg' alt="Women Trips" />
                        <p className='what-provide-svg-text'>Woman Only Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={CorporateProvide} className='what-provide-svg' alt="Corporate Tours" />
                        <p className='what-provide-svg-text'>Corporate Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={CustomiseProvide} className='what-provide-svg' alt="Customised Trips" />
                        <p className='what-provide-svg-text'>Customized Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={BackpackProvide} className='what-provide-svg' alt="Backpacking Tours" />
                        <p className='what-provide-svg-text'>Backpacking Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={LuxuryProvide} className='what-provide-svg' alt="Luxury Trips" />
                        <p className='what-provide-svg-text'>Luxury Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={FamilyProvide} className='what-provide-svg' alt="Family Trip" />
                        <p className='what-provide-svg-text'>Family Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={CoupleProvide} className='what-provide-svg' alt="Couple Trip Honeymoon" />
                        <p className='what-provide-svg-text'>Couple Tours</p>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
    <h2 className='home-page-title1'>What our Customers Say</h2>
    <div style={{width: '80%', margin: ' 2em auto'}}>
      <div class="elfsight-app-aff4bf51-4f83-4057-a94b-31002b3ed0ef" data-elfsight-app-lazy></div>
    </div>
    <h2 className='home-page-title1'>Our Offices</h2>
    <div className="map">
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.61036015461!2d77.58164597369107!3d12.932746115731712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae156577acafbb%3A0xd5c0f2d91c4cd3a2!2sFlip%20To%20Trip!5e0!3m2!1sen!2sin!4v1700731694601!5m2!1sen!2sin" 
        width="100%" 
        height="300em" 
        allowfullscreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
    <div className="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15547.078827194613!2d80.23377870000002!3d13.050325599999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52675ec62fed9f%3A0x5704a359c239177e!2sSri%20Balaji%20Hardwares%20%26%20Electricals!5e0!3m2!1sen!2sin!4v1726210266816!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
    </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;

/* 

<div className="background-container">
        <div className="background-image" style={{backgroundImage: `url(${!isMobile ? images[currentImageIndex] : imagesMobile[currentImageIndexMobile]})`, transition: 'opacity 2s ease-in-out;' }}> 
          <div className="text-container">
            <h1 className='catchPhrase'>Embark on a Journey of a Lifetime</h1>
            <p className='subCatch'>Discover unforgettable travel experiences that will leave you breathless with Flip To Trip</p>
          </div>
          <div>
          </div>
        </div>
      </div>



<p>
          At Flip To Trip, we really love travel. Our goal is to promise you experiences that are 
          special and go beyond what's usual. We believe that every person who travels has a unique story. 
          So, our journey began with the strong idea that travel is more than just going to places; 
          it's about the amazing moments and memories created during each trip. With this in mind, 
          we set out to change how people connect with the world.
        </p>
        <p>
          At Flip To Trip, we're not just a travel company. We see ourselves as storytellers, 
          creating unique stories one personalized journey at a time. We understand that each traveler is different, 
          so we don't use a one-size-fits-all approach. Instead, we customize each adventure to fit what you want. 
          Flip To Trip is an invitation to join us on a special journey, where travel becomes a way to make 
          unforgettable memories. Let's start this adventure together, crafting moments that mean a lot and stories 
          that last forever.
        </p>







        <section className="what-sets-us-apart">
        <h2 className='home-page-title'>Why Choose Us?</h2>
        <div className="point">
          <h3>1. Tailored Experiences</h3>
          <p>
            We understand that your travel aspirations are as unique as you are. 
            That's why we don't believe in one-size-fits-all itineraries. Our team of expert travel designers 
            works closely with you to understand your interests, preferences, and budget, curating a personalized 
            journey that reflects your individuality.
          </p>
        </div>

        <div className="point">
          <h3>2. Global Network</h3>
          <p>
            Through years of cultivating relationships around the globe, we've built a vast network of partners, 
            ensuring that you have access to the best accommodations, guides, and experiences. 
            Whether you're exploring ancient ruins, lounging on pristine beaches, or indulging in local cuisine, 
            we connect you with the best each destination has to offer.
          </p>
        </div>

        <div className="point">
          <h3>3. Seamless Planning</h3>
          <p>
            We understand that your travel aspirations are as unique as you are. 
            That's why we don't believe in one-size-fits-all itineraries. Our team of expert travel designers 
            works closely with you to understand your interests, preferences, and budget, curating a personalized 
            journey that reflects your individuality.
          </p>
        </div>

        <div className="point">
          <h3>4. Safety and Support</h3>
          <p>
            Your safety is our top priority. We work with reputable partners and ensure that all aspects 
            of your journey meet the highest standards. Additionally, our support team is available around the clock, 
            providing peace of mind throughout your travels.
          </p>
        </div>

      </section>

      <section className="our-services">
        <h2>Our Services</h2>
        <ul>
          <li>Customized Itineraries</li>
          <li>Luxury Travel</li>
          <li>Adventure Escapes</li>
          <li>Cultural Immersion</li>
          <li>Group Travel</li>
        </ul>
      </section>

      <section className="start-your-journey">
        <h2>Let's Start Your Journey</h2>
        <p>
          Embark on a travel experience that is uniquely yours. Contact us today,
          and let Flip To Trip transform your travel dreams into cherished
          memories. Your extraordinary adventure awaits!
        </p>
      </section>
*/